import axios from "axios";
import { useStore } from 'vuex';
/**
 * 管理平台请求归集
 */

const store = useStore()

/**
 * 扫码结果请求
 */
export function getOpeScanContent (data) {
    // const data = {
    //     'appId':state.yzj.appid,
    //     'secret':state.yzj.appsecret,
    //     'timestamp':Date.now(),
    //     'scope':'app'
    // }
    return axios({
        // url: 'https://tdlyun.com:9003/api/main/operaSpotcheckBase/scancode',
        url: 'https://ztb.tdlhb.com/api/main/operaSpotcheckBase/scancode',
        method: 'post',
        data: data
    })
}

/**
 * 扫码结果上传
 */
export function putOpeScanContent (data) {
    // const data = {
    //     'appId':state.yzj.appid,
    //     'secret':state.yzj.appsecret,
    //     'timestamp':Date.now(),
    //     'scope':'app'
    // }
    return axios({
        url: 'https://ztb.tdlhb.com/api/main/operaSpotcheck/record',
        method: 'post',
        data: data
    })
}

// ========  润滑 ======== //



/**
 * 扫码结果请求
 */
export function getOpeOilyContent (data) {
    // const data = {
    //     'appId':state.yzj.appid,
    //     'secret':state.yzj.appsecret,
    //     'timestamp':Date.now(),
    //     'scope':'app'
    // }
    return axios({
        // url: 'https://tdlyun.com:9003/api/main/operaSpotcheckBase/scancode',
        url: 'https://ztb.tdlhb.com/api/main/operaOily/scancode',
        method: 'post',
        data: data
    })
}


/**
 * 扫码结果上传
 */
export function putOpeSleekScanContent (data) {
    // const data = {
    //     'appId':state.yzj.appid,
    //     'secret':state.yzj.appsecret,
    //     'timestamp':Date.now(),
    //     'scope':'app'
    // }
    return axios({
        url: 'https://ztb.tdlhb.com/api/main/operaSleek/record',
        method: 'post',
        data: data
    })
}