export default {
    namespaced: true,
    state: {
      appid: '500888804',
      appsecret: '2nsVGASmAXqj4yzOPXfg'
    },
    mutations: {},
    actions: {},                                                                     
    getter:{},
    modules: {},
  };