import axios from "axios";
import { useStore } from 'vuex';
/**
 * 云之家请求归集
 */

const store = useStore()

/**
 * 获取云之家app的accesstoken
 */
export function getYzjAppAccessToken (state) {
    const data = {
        'appId':state.yzj.appid,
        'secret':state.yzj.appsecret,
        'timestamp':Date.now(),
        'scope':'app'
    }
    return axios({
        url: 'https://yunzhijia.com/gateway/oauth2/token/getAccessToken',
        method: 'post',
        data: data
    })
}

/**
 * 获取云之家app的accesstoken
 */
export function refreshYzjAppAccessToken (state,param) {
    const data = {
        'appId':state.yzj.appid,
        'refreshToken':param.refreshToken,
        'timestamp':Date.now(),
        'scope':'app'
    }
    return axios({
        url: 'https://yunzhijia.com/gateway/oauth2/token/refreshToken',
        method: 'post',
        data: data
    })
}

/**
 * 获取云之家app的accesstoken
 */
export function getPersonByOpenid (token,param) {
    const data = {
        'type':'1',
        'array':param.array,
        'eid':param.eid
    }
    return axios({
        url: 'https://yunzhijia.com/gateway/openimport/open/person/get?accessToken='+token,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: JSON.stringify(data),
    })
}

