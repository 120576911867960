import { createStore } from 'vuex'
import yzj from './modules/yunzhijia'

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
      yzj,
    }
})